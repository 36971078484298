import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_ID, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TitleStrategy } from '@angular/router';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { TuiAlertModule, TuiDialogModule, TuiRootModule } from '@taiga-ui/core';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha-2';

import { provideCms } from '@lancelot-frontend/cms';
import { provideComponents } from '@lancelot-frontend/components';
import { provideI18n, provideUiKit } from '@lancelot-frontend/core';
import { ENVIRONMENT, environment } from '@lancelot-frontend/environment';
import { provideForms } from '@lancelot-frontend/forms';

import { CmsLocaleInterceptor } from '../http-interceptors/cms-locale.interceptor';
import { CmsTargetAppInterceptor } from '../http-interceptors/cms-target-app.interceptor';
import { ErrorHandlingInterceptor } from '../http-interceptors/error-handling.interceptor';

import { AppTitleStrategy } from './app-title-strategy';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideAnimations(),
    provideI18n(),
    provideUiKit(),
    provideComponents(),
    provideCms(),
    provideForms(),
    importProvidersFrom(
      TuiRootModule,
      TuiDialogModule,
      TuiAlertModule,
      RecaptchaV3Module,
    ),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    { provide: APP_ID, useValue: 'lancelot-frontend' },
    { provide: ENVIRONMENT, useValue: environment },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CmsLocaleInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CmsTargetAppInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlingInterceptor,
      multi: true,
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCAPTCHA.siteKey },
    { provide: TitleStrategy, useClass: AppTitleStrategy },
    { provide: TRANSLOCO_SCOPE, useValue: 'actions', multi: true },
    { provide: TRANSLOCO_SCOPE, useValue: 'cms', multi: true },
    { provide: TRANSLOCO_SCOPE, useValue: 'common', multi: true },
    { provide: TRANSLOCO_SCOPE, useValue: 'components', multi: true },
    { provide: TRANSLOCO_SCOPE, useValue: 'errors', multi: true },
    { provide: TRANSLOCO_SCOPE, useValue: 'forms', multi: true },
    { provide: TRANSLOCO_SCOPE, useValue: 'nav', multi: true },
    { provide: TRANSLOCO_SCOPE, useValue: 'notifications', multi: true },
    { provide: TRANSLOCO_SCOPE, useValue: 'resources', multi: true },
    { provide: TRANSLOCO_SCOPE, useValue: 'search', multi: true },
    { provide: TRANSLOCO_SCOPE, useValue: 'share', multi: true },
    { provide: TRANSLOCO_SCOPE, useValue: 'status', multi: true },
  ],
};
