import {
  AsyncPipe,
  NgClass,
  NgStyle,
  NgTemplateOutlet,
  PlatformLocation,
  isPlatformServer,
} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  NgZone,
  OnInit,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { EventParams } from '@angular/fire/analytics';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ActivationEnd,
  NavigationEnd,
  ResolveEnd,
  Router,
} from '@angular/router';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { Preferences } from '@capacitor/preferences';
import { SplashScreen } from '@capacitor/splash-screen';
import {
  AppUpdate,
  AppUpdateAvailability,
  FlexibleUpdateInstallStatus,
} from '@capawesome/capacitor-app-update';
import { GestureController, Platform } from '@ionic/angular';
import { TranslocoDirective } from '@ngneat/transloco';
import {
  TuiAlertContext,
  TuiDestroyService,
  TuiLetModule,
} from '@taiga-ui/cdk';
import {
  TUI_ALERT_POSITION,
  TuiAlertModule,
  TuiAlertService,
  TuiButtonModule,
  TuiDialogModule,
  TuiDialogService,
  TuiModeModule,
  TuiRootModule,
} from '@taiga-ui/core';
import { TuiBadgeModule, TuiMarkerIconModule } from '@taiga-ui/kit';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { BehaviorSubject, combineLatest, from, of, zip } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeWith,
  sample,
  skipUntil,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import {
  ErrorOverlayComponent,
  LoadingOverlayComponent,
  LogoComponent,
  OverlayComponent,
  PullToRefreshComponent,
  stringifyPersonInformation,
} from '@lancelot-frontend/components';
import {
  DocumentHeadService,
  LangService,
  ROUTER_SCROLL_SERVICE,
} from '@lancelot-frontend/core';
import { EnvironmentService } from '@lancelot-frontend/environment';
import { FormCheckboxComponent, FormComponent } from '@lancelot-frontend/forms';
import { CONSENT_MODE_PREF_KEY } from './app.constants';
import { AppService } from './app.service';
import { AppAnalyticsService } from './app-analytics.service';
import { AppTitleStrategy } from './app-title-strategy';
import { Layout, Layouts } from './layout/app.layout';
import { SignedInUserService } from './user/user.service';

@Component({
  selector: 'ffb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgStyle,
    NgTemplateOutlet,
    AsyncPipe,
    TuiRootModule,
    PullToRefreshComponent,
    TuiBadgeModule,
    Layout,
    LoadingOverlayComponent,
    TranslocoDirective,
    ErrorOverlayComponent,
    OverlayComponent,
    LogoComponent,
    TuiModeModule,
    TuiButtonModule,
    TuiDialogModule,
    TuiAlertModule,
    TuiLetModule,
    TuiMarkerIconModule,
    FormComponent,
    FormsModule,
    ReactiveFormsModule,
    FormCheckboxComponent,
  ],
  providers: [
    TuiDestroyService,
    {
      provide: TUI_ALERT_POSITION,
      useFactory: (platform: Platform) =>
        platform.is('mobile') ? 'auto 1rem 1rem auto' : `2rem 3rem 0 auto`,
      deps: [Platform],
    },
  ],
})
export class AppComponent implements OnInit, AfterViewInit {
  private readonly platformId = inject(PLATFORM_ID);
  private readonly zone = inject(NgZone);
  private ref = inject(ChangeDetectorRef);
  private router = inject(Router);
  private location = inject(PlatformLocation);
  private element = inject(ElementRef);
  private readonly destroy$ = inject(TuiDestroyService);
  private readonly alertService = inject(TuiAlertService);
  protected readonly dialogService = inject(TuiDialogService);
  private gestureCtrl = inject(GestureController);
  private readonly routerScrollService = inject(ROUTER_SCROLL_SERVICE, {
    optional: true,
  });
  private readonly analyticsService = inject(AppAnalyticsService);
  private environmentService = inject(EnvironmentService);
  private documentHeadService = inject(DocumentHeadService);
  private appTitleStrategy = inject(AppTitleStrategy);
  private langService = inject(LangService);
  protected app = inject(AppService);
  protected signedInUserService = inject(SignedInUserService);

  isNativePlatform = this.app.isNativePlatform;
  isPlatformServer = isPlatformServer(this.platformId);
  protected readonly outdated$ = new BehaviorSubject<boolean | undefined>(
    this.isPlatformServer ? false : undefined,
  );
  lastVersionCheckTimestamp?: number;
  environment?: string;
  loading?: boolean;
  refreshing?: boolean;
  waiting?: boolean;
  connected?: boolean;
  underMaintenance?: boolean;
  hasDialogsOpen = false;
  flexibleUpdateInstallStatus?: FlexibleUpdateInstallStatus;

  @ViewChild('appUpdateAvailableTemplate')
  appUpdateAvailableTemplate?: TemplateRef<TuiAlertContext<never>>;
  @ViewChild('appUpdateDownloadingTemplate')
  appUpdateDownloadingTemplate?: TemplateRef<TuiAlertContext<never>>;
  @ViewChild('appUpdateErrorTemplate')
  appUpdateErrorTemplate?: TemplateRef<TuiAlertContext<never>>;
  @ViewChild('appUpdateReadyTemplate')
  appUpdateReadyTemplate?: TemplateRef<TuiAlertContext<never>>;

  constructor() {
    // @ts-expect-error dialogs$ is protected, but we can still read it
    this.dialogService.dialogs$
      .pipe(takeUntil(this.destroy$))
      .subscribe((dialogs) => {
        this.hasDialogsOpen = !!dialogs.length;
      });

    combineLatest([
      this.outdated$,
      this.app.loading$.pipe(distinctUntilChanged()),
      this.app.apiDown$.pipe(distinctUntilChanged()),
      this.app.cmsDown$.pipe(distinctUntilChanged()),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([outdated, loading, apiDown, cmsDown]) => {
        setTimeout(() => {
          this.loading =
            this.isPlatformServer || (loading && !apiDown && !cmsDown);
          if (this.isNativePlatform && (!this.loading || outdated)) {
            Preferences.get({ key: CONSENT_MODE_PREF_KEY })
              .then(({ value }) => {
                if (!value) {
                  if (this.app.isIOS) {
                    AppTrackingTransparency.getStatus().then(({ status }) => {
                      if (status === 'notDetermined') {
                        AppTrackingTransparency.requestPermission().then(
                          ({ status }) => {
                            if (status === 'authorized') {
                              this.analyticsService.openAxeptioCookies();
                            }
                          },
                        );
                      } else {
                        if (status === 'authorized') {
                          this.analyticsService.openAxeptioCookies();
                        }
                      }
                    });
                  } else {
                    this.analyticsService.openAxeptioCookies();
                  }
                } else {
                  this.analyticsService.setConsent(JSON.parse(value));
                }
                SplashScreen.hide();
              })
              .catch(() => {
                SplashScreen.hide();
              });
          }
        });
      });

    this.app.refreshingContent$
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((refreshing) => {
        this.refreshing = refreshing;
      });

    this.app.waiting$
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((waiting) => {
        this.waiting = waiting;
      });

    this.app.connected$
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((connected) => {
        this.connected = connected;
      });

    combineLatest([
      this.app.apiUnderMaintenance$.pipe(distinctUntilChanged()),
      this.app.cmsUnderMaintenance$.pipe(distinctUntilChanged()),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([apiUnderMaintenance, cmsUnderMaintenance]) => {
        this.underMaintenance = apiUnderMaintenance || cmsUnderMaintenance;
      });

    if (!this.isPlatformServer) {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker
          .getRegistrations()
          .then(function (registrations) {
            for (const registration of registrations) {
              registration.unregister();
            }
          })
          .catch(() => {
            // Do nothing if fhe user denied permission to use Service Worker.
          });
      }

      this.checkVersion();

      Network.getStatus().then(this.setNetworkStatus.bind(this));
      Network.addListener(
        'networkStatusChange',
        this.setNetworkStatus.bind(this),
      );

      this.langService.init();

      this.router.events
        .pipe(
          filter(
            (event): event is NavigationEnd => event instanceof NavigationEnd,
          ),
          skipUntil(
            this.signedInUserService.signedInUser$.pipe(
              filter((user) => user !== undefined),
            ),
          ),
          mergeWith(
            this.router.events.pipe(
              filter(
                (event): event is NavigationEnd =>
                  event instanceof NavigationEnd,
              ),
              sample(
                this.signedInUserService.signedInUser$.pipe(
                  filter((user) => user !== undefined),
                ),
              ),
              take(1),
            ),
          ),
          switchMap(() =>
            from(
              this.appTitleStrategy.getTitle(this.router.routerState.snapshot),
            ).pipe(
              map((pageTitle): EventParams => {
                const url = new URL(this.location.href);
                return {
                  page_title: pageTitle || '',
                  page_path: url.pathname,
                  page_location: url.href,
                };
              }),
            ),
          ),
          takeUntil(this.destroy$),
        )
        .subscribe((params) => {
          this.analyticsService.logEvent('page_view', params);
        });
    }

    if (this.isNativePlatform) {
      // Handle deep links
      App.addListener('appUrlOpen', ({ url }) => {
        this.zone.run(() => {
          try {
            const { pathname, search } = new URL(url);
            SplashScreen.show();
            this.router.navigateByUrl(pathname + search).finally(() => {
              SplashScreen.hide();
            });
          } catch {
            // NOOP
          }
        });
      });
    }
  }

  ngOnInit() {
    this.environment = this.environmentService.get('envName');

    // Set embedded and loginAs modes regarding route query params on first load
    if (!this.isNativePlatform) {
      this.router.events
        .pipe(
          filter(
            (event): event is ActivationEnd => event instanceof ActivationEnd,
          ),
          take(1),
        )
        .subscribe((event) => {
          if (event.snapshot.queryParams.embedded !== undefined) {
            this.app.embedded =
              event.snapshot.queryParams.embedded === 'false' ? false : true;
          }
          if (event.snapshot.queryParams.loginAs !== undefined) {
            this.app.loginAs = event.snapshot.queryParams.loginAs;
          }
        });
    }

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      // Set layout regarding current route
      // - on first load
      if (event instanceof ActivationEnd && !this.app.layout) {
        this.app.layout = event.snapshot.data.layout ?? Layouts.Public;
      }
      // - and after a navigation
      if (event instanceof ResolveEnd) {
        let layout = Layouts.Public;
        let child = event.state.root?.firstChild;
        layout = child?.data.layout ?? layout;
        while (child?.firstChild) {
          child = child.firstChild;
          layout = child?.data.layout ?? layout;
        }
        this.app.layout = layout;
      }
      // Update Open Graph Meta Tag for URL after each navigation
      if (event instanceof NavigationEnd) {
        this.documentHeadService.setUrl(
          this.environmentService.get('baseUrl', '') + event.url,
        );
      }
    });
  }

  ngAfterViewInit() {
    if (this.isNativePlatform) {
      // handle swipe back on iOS
      if (Capacitor.getPlatform() === 'ios') {
        const gesture = this.gestureCtrl.create({
          gestureName: 'swipe-back',
          el: this.element.nativeElement,
          direction: 'x',
          onEnd: ({ startX, deltaX }) => {
            if (startX < 30 && deltaX > 150) {
              // @ts-expect-error dialogs$ is protected, but we can still read it
              const dialogs = this.dialogService.dialogs$.getValue();
              const dialog = dialogs[dialogs.length - 1];
              if (dialog) {
                if (dialog.closeable || dialog.dismissible) {
                  dialog.completeWith(undefined);
                }
              } else {
                this.app.back();
              }
            }
            this.ref.detectChanges();
          },
        });

        gesture.enable();
      }

      // handle back button on Android
      if (Capacitor.getPlatform() === 'android') {
        App.addListener('backButton', ({ canGoBack }) => {
          // @ts-expect-error dialogs$ is protected, but we can still read it
          const dialogs = this.dialogService.dialogs$.getValue();
          const dialog = dialogs[dialogs.length - 1];
          if (dialog) {
            if (dialog.closeable || dialog.dismissible) {
              dialog.completeWith(undefined);
              this.ref.detectChanges();
            }
          } else {
            if (canGoBack) {
              this.app.back();
            } else {
              App.exitApp();
            }
          }
        });
      }
    }
  }

  @HostListener('window:focus')
  onFocus() {
    this.app.focused = true;
    // check version
    this.checkVersion();
  }

  @HostListener('window:blur')
  onBlur() {
    this.app.focused = false;
  }

  checkVersion() {
    // check version if last check timestamp is more than 1 minute old
    if (this.app.isInIframe) {
      this.outdated$.next(false);
    } else if (
      !this.lastVersionCheckTimestamp ||
      Date.now() - this.lastVersionCheckTimestamp > 1000 * 60
    ) {
      this.lastVersionCheckTimestamp = Date.now();

      zip(
        this.app.outdated$,
        from(AppUpdate.getAppUpdateInfo()).pipe(
          catchError(() => of(undefined)),
        ),
      )
        .pipe(takeUntil(this.destroy$))
        .subscribe(([outdated, updateInfo]) => {
          if (
            updateInfo &&
            updateInfo.updateAvailability ===
              AppUpdateAvailability.UPDATE_AVAILABLE
          ) {
            if (Capacitor.getPlatform() === 'ios' && !outdated) {
              this.alertService
                .open(this.appUpdateAvailableTemplate, {
                  autoClose: false,
                })
                // eslint-disable-next-line rxjs/no-nested-subscribe
                .subscribe();
            }
            if (Capacitor.getPlatform() === 'android')
              if (outdated) {
                AppUpdate.performImmediateUpdate();
              } else {
                AppUpdate.startFlexibleUpdate();
                AppUpdate.addListener(
                  'onFlexibleUpdateStateChange',
                  ({ installStatus }) => {
                    if (installStatus !== this.flexibleUpdateInstallStatus) {
                      this.flexibleUpdateInstallStatus = installStatus;

                      if (
                        installStatus ===
                        FlexibleUpdateInstallStatus.DOWNLOADING
                      ) {
                        this.alertService
                          .open(this.appUpdateDownloadingTemplate)
                          // eslint-disable-next-line rxjs/no-nested-subscribe
                          .subscribe();
                      } else if (
                        installStatus === FlexibleUpdateInstallStatus.DOWNLOADED
                      ) {
                        this.alertService
                          .open(this.appUpdateReadyTemplate, {
                            autoClose: false,
                          })
                          // eslint-disable-next-line rxjs/no-nested-subscribe
                          .subscribe();
                      } else if (
                        [
                          FlexibleUpdateInstallStatus.CANCELED,
                          FlexibleUpdateInstallStatus.FAILED,
                        ].includes(installStatus)
                      ) {
                        this.alertService
                          .open(this.appUpdateErrorTemplate, {
                            status: 'error',
                          })
                          // eslint-disable-next-line rxjs/no-nested-subscribe
                          .subscribe();
                      }
                    }
                  },
                );
              }
          }

          this.outdated$.next(outdated);
        });
    }
  }

  setNetworkStatus({ connected }: ConnectionStatus) {
    this.app.connected = connected;
  }

  updateApp() {
    if (this.isNativePlatform) {
      AppUpdate.openAppStore();
    } else {
      this.reload();
    }
  }

  completeFlexibleUpdate() {
    AppUpdate.completeFlexibleUpdate().then(() => {
      AppUpdate.removeAllListeners();
    });
  }

  removeLoginAs() {
    this.app.loginAs = null;
  }

  reload() {
    window.location.reload();
  }

  protected readonly stringifyPersonInformation = stringifyPersonInformation;
}
